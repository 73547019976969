'use client';

import { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { BreadcrumbJsonLd } from 'next-seo';

import { Container } from '@/components/core/flexbox';
import Subtitle from './Subtitle';
import ListGraphicElements from './graphic-elements/ListGraphicElements';
import HighlightedPost from './posts/Highlighted';
import Categories from './Categories';
import PostsList from './posts/PostsList';
import Subscriber from './Subscriber';
/*import SearchInput from '@/components/core/Form/Search';*/
import CTA from '@/components/core/CTA';
import BlogService from '@/services/blog';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';
import POST_TYPES from '@/components/shared/Blog/post-types';
import { Layout, Title } from '@/components/core';

function getLimit(isTablet, isMobile, isPress) {
  if (isPress) return 9;
  if (isTablet) return 6;
  if (isMobile) return 6;
  return 12;
}

const Blog = ({
  bannerInfo,
  content,
  highlightedPost,
  categories,
  mostRecent,
  shared,
  postsLists,
  postType,
  hasReadingTime,
}) => {
  const { seo, header } = content;
  const isPress = postType.name === POST_TYPES.PRESS_PAGE.name;

  const categoriesRef = useRef();
  const [selectedCategory, setSelectedCategory] = useState(isPress ? 'all' : null);
  /*const [search, setSearch] = useState('');*/
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [posts, setPosts] = useState(isPress ? postsLists : null);
  const [hasError, setHasError] = useState(false);

  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);
  const isTablet = useBetterMediaQuery(
    `(min-width: ${theme.breakpoints.min_md}px) and (max-width: ${theme.breakpoints.max_md}px)`,
  );

  const { subscribe, cta } = shared;

  const goToCategories = () => {
    window.scrollTo({ behavior: 'smooth', top: categoriesRef.current.offsetTop - 120 });
  };

  const goBack = () => {
    setSelectedCategory(null);
    goToCategories();
  };

  const fetchData = async ({ category, cleanData }) => {
    try {
      setHasError(false);
      if (!category) setIsLoadingMore(true);
      const resp = await BlogService.getPosts({
        postType: postType.name,
        category: category,
        page: !cleanData ? posts.meta.pagination.page + 1 : 0,
        excludedId: highlightedPost?.id,
        limit: getLimit(isTablet, isMobile, isPress),
      });
      if (!category) setIsLoadingMore(false);
      setPosts(!cleanData ? { ...resp, data: posts.data.concat(resp.data) } : resp);
    } catch {
      setHasError(true);
    }
  };

  const changeCategory = async (category) => {
    setIsLoading(true);
    setPosts(null);
    setSelectedCategory(category);
    goToCategories();
    await fetchData({ category, cleanData: true });
    goToCategories();
    setIsLoading(false);
  };

  return (
    <Layout bannerInfo={bannerInfo}>
      {seo?.title && (
        <BreadcrumbJsonLd
          useAppDir={true}
          itemListElements={[
            {
              position: 1,
              name: seo.title,
              item: `${process.env.NEXT_PUBLIC_DEPLOYMENT_URL}/${POST_TYPES.BLOG_POST.url}`,
            },
          ]}
        />
      )}
      <PageWrapper press={isPress} selectedCategory={selectedCategory}>
        <Container>
          <ContentWrapper>
            <Title as="h1" size="2xl" data-testid="title">
              {header?.title}
            </Title>
            <Subtitle data-testid="subtitle">{header?.subtitle}</Subtitle>
            {!isTablet && !isMobile && <ListGraphicElements />}
          </ContentWrapper>
        </Container>

        {!isPress && (
          <HighlightedPost
            post={highlightedPost}
            postType={postType}
            hasReadingTime={hasReadingTime}
          />
        )}

        {(!!mostRecent?.length || !!postsLists?.length || isPress) && (
          <Filters press={isPress} ref={categoriesRef}>
            <FiltersLine
              // temporary disabled until we have a search endpoint
              $hasSearch={false}
            >
              <Categories
                categories={categories}
                selectedCategory={selectedCategory}
                changeCategory={changeCategory}
                loading={isLoading}
              />
              {/*   <SearchInput
              placeholder="Search"
              ariaLabel="Search"
              value={search}
              onChange={setSearch}
              disabled={isLoading}
              clear
            />*/}
            </FiltersLine>
          </Filters>
        )}

        {!selectedCategory && (
          <>
            <PostsList
              mostRecent
              category="Most Recent"
              posts={mostRecent}
              seeAll={() => changeCategory('all')}
              hideWhenEmpty
              postType={postType}
              hasReadingTime={hasReadingTime}
            />

            {postsLists?.slice(0, 3).map((postsList) => (
              <PostsList
                key={postsList.category?.name}
                category={postsList.category?.displayName}
                posts={postsList.posts}
                seeAll={() => changeCategory(postsList.category?.name)}
                hideWhenEmpty
                postType={postType}
                hasReadingTime={hasReadingTime}
              />
            ))}

            {!isPress &&
              (isMobile ? (
                <SubscribeList label={subscribe} />
              ) : (
                <Container data-testid="subscriber-list">
                  <SubscribeList label={subscribe} />
                </Container>
              ))}

            {postsLists?.slice(3, postsLists.length).map((postsList) => (
              <PostsList
                key={postsList.category?.name}
                category={postsList.category?.displayName}
                posts={postsList.posts}
                seeAll={() => changeCategory(postsList.category?.name)}
                hideWhenEmpty
                postType={postType}
                hasReadingTime={hasReadingTime}
              />
            ))}
          </>
        )}

        {selectedCategory && (
          <>
            <PostsList
              category={selectedCategory}
              posts={posts?.data}
              meta={posts?.meta}
              back={goBack}
              loading={isLoading}
              loadingMore={isLoadingMore}
              loadMore={() => fetchData({ category: selectedCategory, cleanData: false })}
              sliderInMobile={false}
              hasError={hasError}
              list
              postType={postType}
              hasReadingTime={hasReadingTime}
            />
            {!isPress && (
              <Container>
                <SubscriberAll label={subscribe} />
              </Container>
            )}
          </>
        )}
      </PageWrapper>

      {!isPress && cta && <CTA {...cta} />}
    </Layout>
  );
};

const SubscribeList = styled(Subscriber)`
  margin-top: 60px;
  margin-bottom: 88px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 36px;
    margin-bottom: 64px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 28px;
    margin-bottom: 56px;
  }
`;

const SubscriberAll = styled(Subscriber)`
  padding: 76px 0 72px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 78px 0 88px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 68px 0 0;
  }
`;

export const PageWrapper = styled.div`
  padding: 152px 0 88px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 120px 0 56px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 112px 0 0;
  }

  ${(props) =>
    props.selectedCategory &&
    css`
      padding-bottom: ${({ press }) => (press ? 88 : 32)}px;
      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        padding-bottom: ${({ press }) => (press ? 64 : 16)}px;
      }
      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        padding-bottom: ${({ press }) => (press ? 40 : 0)}px;
      }
    `}
`;

export const ContentWrapper = styled.div`
  position: relative;
`;

const Filters = styled(Container)`
  margin-top: 64px;
  margin-bottom: ${({ press }) => (press ? 26 : 64)}px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 56px;
    margin-bottom: ${({ press }) => (press ? -10 : 40)}px;
    padding: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 48px;
    margin-bottom: ${({ press }) => (press ? -20 : 40)}px;
  }
`;

const FiltersLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: nowrap;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: block;
    padding: 0;
  }

  ${(props) =>
    props.$hasSearch &&
    css`
      @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
        > :first-child {
          max-width: calc(100% - 300px);
        }
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        > :first-child > * {
          padding-bottom: 20px;
        }

        > :nth-child(2) {
          margin-left: 24px;
          margin-right: 24px;
          margin-top: 12px;
        }
      }
    `}
`;

export default Blog;
