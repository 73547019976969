import Image from 'next/image';
import styled from 'styled-components';
import Link from 'next/link';
import MarkdownRichText from '../../MarkdownRichText';
import { Container } from '@/components/core/flexbox';
import ReadingTime from '@/components/ReadingTime';
import getImgSrcWorkaround from '@/utils/images';

const Highlighted = ({ post, postType, hasReadingTime }) => {
  if (!post) return null;

  const {
    title,
    publishedOn,
    publishedAt,
    featuredImage,
    content,
    shortDescription,
    slug,
    wordCount,
  } = post;
  const publishedDate = publishedOn || publishedAt;
  const category = post?.category?.displayName;

  return (
    <HighlightedContainer data-testid="highlighted-container">
      <Link href={`/${postType.url}/${slug}`}>
        <Post>
          <Content>
            {category && <Category data-testid="category">{category}</Category>}
            <Title data-testid="title">{title}</Title>
            <DateWrapper>
              <DateInfo data-testid="published-on">
                {new Date(publishedDate).toLocaleDateString('en-US', { dateStyle: 'long' })}
              </DateInfo>
              <StyledReadingTime
                content={content}
                wordCount={wordCount}
                hasReadingTime={hasReadingTime}
              />
            </DateWrapper>

            <Description data-testid="description">
              {shortDescription ? shortDescription : <MarkdownRichText>{content}</MarkdownRichText>}
            </Description>
          </Content>
          <div>
            <ImageWrapper>
              {featuredImage && (
                <Image
                  data-testid="feature-image"
                  priority
                  src={getImgSrcWorkaround(featuredImage?.url)}
                  alt={featuredImage?.alternativeText || ''}
                  fill
                  sizes="(max-width: 768px) 100vw, (max-width: 991px) 50vw, 632px"
                />
              )}
            </ImageWrapper>
          </div>
        </Post>
      </Link>
    </HighlightedContainer>
  );
};

const HighlightedContainer = styled(Container)`
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const Post = styled.div`
  margin-top: 72px;
  min-height: 460px;
  background-color: ${(props) => props.theme.colors.neutral.default};
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 0 1 auto;
  width: 100%;

  animation-name: fadeIn;
  animation-duration: 2s;

  > div {
    flex-basis: 50%;
    max-width: 50%;

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      flex-basis: 100%;
      max-width: 100%;
      &:nth-child(2) {
        order: -1;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    min-height: 368px;
    margin-top: 56px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 48px;
    border-radius: 0;
  }
`;

const Content = styled.div`
  box-sizing: border-box;
  padding: 96px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 48px 32px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 40px 24px;
    order: 2;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;
  background-color: ${(props) => props.theme.colors.secondary.light};
  img {
    object-fit: cover;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    min-height: 368px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    min-height: 224px;
  }
`;

const Category = styled.div`
  font-family: ${(props) => props.theme.font.family.heading};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 16px;
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.font.family.heading};
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  margin-bottom: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 96px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 18px;
  }
`;

const DateWrapper = styled.div`
  display: inline-flex;
  margin-bottom: 16px;
  font-size: 14px;
`;

const DateInfo = styled.div`
  opacity: 0.7;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 28px;
  opacity: 0.8;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 140px;
`;

const StyledReadingTime = styled(ReadingTime)`
  position: relative;
  padding-left: 22px;
  &:before {
    position: absolute;
    content: '';
    top: 10px;
    left: 8px;
    height: 6px;
    width: 6px;
    background-color: ${(props) => props.theme.colors.grey.dark};
    border-radius: 50%;
    opacity: 0.3;
`;

export default Highlighted;
