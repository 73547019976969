import styled, { keyframes, css } from 'styled-components';

export const slideIn = keyframes`
from {
  opacity: 0;
  transform: translateY(-10px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
`;

export const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;

export const Post = styled.a`
  ${({ animation }) =>
    animation === 'slideIn' &&
    css`
      animation: 2s ${slideIn};
    `};

  ${({ animation }) =>
    animation === 'fadeIn' &&
    css`
      animation: 1s ${fadeIn};
    `};
`;

export const Title = styled.div`
  font-family: ${(props) => props.theme.font.family.heading};
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  position: relative;
`;

export const FeaturedImage = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;

  img {
    animation: 1s ${fadeIn};
    object-fit: cover;
  }
`;

export const SecondaryImage = styled.div`
  position: relative;
  ${(props) =>
    props.career &&
    css`
      height: 27px;
      margin-bottom: 24px;
    `}

  img {
    object-fit: contain;
    object-position: top;
    width: 100%;
    height: 50px;
    ${(props) =>
      props.career &&
      css`
        object-position: left top;
        height: 27px;
      `}
  }
`;
