const baseURL = 'resources';

const resourcePageSlugs = {
  WEBINAR_POST: 'webinars',
  PATENTS: 'patents',
  CLINICAL_POST: 'clinical-studies',
  WHITE_PAPER: 'whitepapers',
  CASE_STUDY: 'case-studies',
  PRESS_PAGE: 'press',
  TECH_POST: 'tech',
};

export const RESOURCES_BLOG_POST_TYPES = {
  CASE_STUDY: {
    name: 'case-study',
    namePlural: 'case-studies',
    url: `${baseURL}/${resourcePageSlugs.CASE_STUDY}`,
    label: 'Case Study',
    pageSlug: resourcePageSlugs.CASE_STUDY,
    pageApiName: 'case-studies-page',
  },
  CLINICAL_POST: {
    name: 'clinical-post',
    namePlural: 'clinical-posts',
    url: `${baseURL}/${resourcePageSlugs.CLINICAL_POST}`,
    label: 'Clinical Study',
    pageSlug: resourcePageSlugs.CLINICAL_POST,
    pageApiName: 'clinical-study',
  },
  TECH_POST: {
    name: 'tech-post',
    namePlural: 'tech-posts',
    url: `${baseURL}/${resourcePageSlugs.TECH_POST}`,
    label: 'Newsroom',
    pageSlug: resourcePageSlugs.TECH_POST,
    pageApiName: 'tech-blog',
  },
  WHITE_PAPER: {
    name: 'white-paper-post',
    namePlural: 'white-paper-posts',
    url: `${baseURL}/${resourcePageSlugs.WHITE_PAPER}`,
    label: 'White Paper',
    pageSlug: resourcePageSlugs.WHITE_PAPER,
    pageApiName: 'white-paper-page',
  },
  WEBINAR_POST: {
    name: 'webinar-post',
    namePlural: 'webinar-posts',
    url: `${baseURL}/${resourcePageSlugs.WEBINAR_POST}`,
    label: 'On-demand Webinar',
    pageSlug: resourcePageSlugs.WEBINAR_POST,
    pageApiName: 'webinar-page',
  },
  PRESS_PAGE: {
    name: 'press-post',
    namePlural: 'press-posts',
    url: `${baseURL}/${resourcePageSlugs.PRESS_PAGE}`,
    label: 'Newsroom',
    pageSlug: resourcePageSlugs.PRESS_PAGE,
    pageApiName: 'press',
  },
};

const POST_TYPES = {
  ...RESOURCES_BLOG_POST_TYPES,
  BLOG_POST: { name: 'blog-post', namePlural: 'blog-posts', url: `newsroom`, label: 'Newsroom' },
  WEBINAR: { name: 'webinar', namePlural: 'webinars', url: `webinars`, label: 'Upcoming Webinar' },
  PATENTS: {
    name: 'patents',
    url: `${baseURL}/${resourcePageSlugs.PATENTS}`,
    label: 'Patents',
  },
};

export function getPostType(component) {
  switch (component) {
    case 'resources.case-study':
    case 'resources.case-study-simple':
    case 'card-list.case-study-content-link':
      return {
        ...POST_TYPES.CASE_STUDY,
        featuredItemType: 'caseStudy',
      };
    case 'resources.study':
    case 'resources.study-simple':
    case 'card-list.clinical-study-content-link':
      return {
        ...POST_TYPES.CLINICAL_POST,
        featuredItemType: 'clinicalPost',
      };
    case 'resources.white-paper':
    case 'resources.white-paper-simple':
    case 'card-list.whitepaper-content-link':
      return {
        ...POST_TYPES.WHITE_PAPER,
        featuredItemType: 'whitePaperPost',
      };
    case 'resources.webinar':
    case 'resources.webinar-simple':
    case 'card-list.webinar-content-link':
      return {
        ...POST_TYPES.WEBINAR_POST,
        featuredItemType: 'webinarPost',
      };
    default:
      return {
        ...POST_TYPES.BLOG_POST,
        featuredItemType: 'blogPost',
      };
  }
}

export function getFeaturedCategoryUrl(categoryName) {
  switch (categoryName) {
    case 'webinars':
      return POST_TYPES.WEBINAR_POST.url;
    case 'patents':
      return POST_TYPES.PATENTS.url;
    case 'clinicalStudies':
      return POST_TYPES.CLINICAL_POST.url;
    case 'whitePapers':
      return POST_TYPES.WHITE_PAPER.url;
    case 'caseStudies':
      return POST_TYPES.CASE_STUDY.url;
    default:
      return '/';
  }
}

export function getPostUrl(slug, component) {
  const postType = getPostType(component);

  return `/${postType.url}/${slug}`;
}

export default POST_TYPES;
