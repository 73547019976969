'use client';

import { useState, useEffect } from 'react';

export default function useReadingTime(content, wordCount, wordsPerMinute = 250) {
  const [readingTime, setReadingTime] = useState(null);
  useEffect(() => {
    if (!content?.length && !wordCount) {
      return;
    }

    const words = wordCount || content.match(/\w+/g)?.length;

    if (isNaN(words)) {
      return;
    }

    setReadingTime(Math.ceil(words / wordsPerMinute));
  }, [content, wordCount, wordsPerMinute]);

  return readingTime;
}
