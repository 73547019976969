import styled from 'styled-components';
import { Container } from '@/components/core/flexbox';
import InputSubscriber from './InputSubscriber';

const Subscriber = ({ forwardRef, label, className }) => (
  <SubscriberWrapper ref={forwardRef} className={className}>
    <SubscriberInner>
      <Title data-testid="label">{label}</Title>
      <InputSubscriber />
    </SubscriberInner>
  </SubscriberWrapper>
);

const SubscriberWrapper = styled.div`
  margin: 104px auto;
  max-width: 1264px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin: 80px auto;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin: 64px auto;
  }
`;
const SubscriberInner = styled(Container)`
  padding: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${(props) => props.theme.colors.primary.light};
  border-radius: 4px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 56px;
    padding-bottom: 64px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 48px 0 64px 0;
  }
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.font.family.heading};
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 536px;
    width: 100%;
    text-align: center;
    font-size: 25px;
    line-height: 32px;
    padding-top: 0;
    padding-bottom: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 272px;
    font-size: 20px;
    line-height: 24px;
  }
`;

export default Subscriber;
