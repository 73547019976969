'use client';

import styled, { css } from 'styled-components';
import Slider from 'react-slick';
import { SinglePost } from './SinglePost';
import { useCallback, useState } from 'react';
import { useMemo } from 'react';

const PostsSlider = ({
  posts,
  postWithBackground,
  minimal,
  career,
  showDate = true,
  ...cardProps
}) => {
  const [dragging, setDragging] = useState(false);
  const beforeChange = useCallback(() => setDragging(true), [setDragging]);
  const afterChange = useCallback(() => setDragging(false), [setDragging]);
  const onClick = useCallback(
    (e) => {
      if (dragging) {
        e.preventDefault();
      }
    },
    [dragging],
  );

  const settings = useMemo(
    () => ({
      infinite: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      afterChange,
      beforeChange,
      customPaging: (i) => <button type="button">{i + 1}</button>,
    }),
    [afterChange, beforeChange],
  );

  if (!posts || !posts.length) return null;

  return (
    <SliderWrapper
      dots={posts.length > 1}
      postWithBackground={postWithBackground}
      minimal={minimal}
      career={career}
    >
      <Slider {...settings}>
        {posts.map((post) => (
          <Post
            post={post}
            key={post?.id}
            postWithBackground={postWithBackground}
            minimal={minimal}
            onClick={onClick}
            showDate={showDate}
            career={career}
            {...cardProps}
          />
        ))}
      </Slider>
    </SliderWrapper>
  );
};

const SliderWrapper = styled.div`
  margin-bottom: ${(props) => (props.minimal ? 0 : '40px')};

  ${(props) =>
    props.dots &&
    css`
      padding-bottom: 10px;
    `};

  .slick-dots {
    padding: 0 24px;
    text-align: ${(props) => (props.career ? 'center' : 'left')};
    height: 10px;
    bottom: -10px;
    li {
      &:first-child {
        margin-left: 0;
      }
      width: 10px;
      height: 10px;
      margin: 0 4px;
      top: -13px;
      &.slick-active {
        button {
          background-color: #1f222c;
        }
      }
      button {
        padding: 0;
        background-color: transparent;
        border: 2px solid #1f222c;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        opacity: 0.3;
        &:before {
          display: none;
        }
      }
    }
  }

  ${(props) =>
    props.postWithBackground &&
    css`
      .slick-dots {
        margin: 0;
        text-align: center;
      }
    `};

  ${(props) =>
    props.minimal &&
    css`
      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        .slick-slider {
          left: 50%;
          margin-left: -50vw;
          margin-right: -50vw;
          overflow: hidden;
          padding: 0 18px;
          right: 50%;
          width: 100vw;
        }

        .slick-list {
          overflow: visible;
        }
      }

      .slick-dots {
        bottom: auto;
        margin-top: 24px;
        position: relative;
        text-align: center;
      }

      .slick-slide:not(:only-child) {
        padding: 0 6px;
      }
    `};
`;

const Post = styled(SinglePost)`
  -webkit-user-drag: none;
  touch-action: none;

  ${(props) =>
    props.postWithBackground &&
    css`
      display: grid;
      grid-template-rows: auto 1fr;
    `};
`;

export default PostsSlider;
