import styled, { css } from 'styled-components';
import {
  FeaturedImage as DefaultFeaturedImage,
  Post as DefaultPost,
  Title as DefaultTitle,
} from './SinglePostStyles';

export const Title = styled(DefaultTitle)`
  margin: 24px 0 16px 0;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 16px;
    margin: 16px 0;
  }
`;

export const Content = styled.div`
  box-sizing: border-box;
  ${(props) =>
    props.press &&
    css`
      border-radius: 4px;
      height: 290px;
      background-color: ${(props) => props.theme.colors.neutral.default};
      padding: 32px;

      :hover {
        background-color: ${(props) => props.theme.colors.dark_neutral};
      }

      ${Title} {
        max-height: min-content;
        font-weight: 500;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 28px;
        :hover {
          text-decoration-line: underline;
          text-decoration-thickness: 1px;
        }
      }
      ${(props) =>
        props.career &&
        css`
          min-height: 290px;
          height: 100%;
          padding: 48px;
          background-color: ${(props) => props.theme.colors.dark_neutral};
          border-radius: 8px;
          border: 2px solid #fff;
          @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
            min-width: 240px;
            margin: 0 24px;
            padding: 32px;
          }
        `}
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: auto;
  }
`;

export const Post = styled(DefaultPost)`
  display: block;
  margin: 20px 0 36px 0;

  ${(props) =>
    props.postWithBackground &&
    css`
      ${Content} {
        height: 100%;
        padding: 40px 32px;
        background-color: ${(props) => props.theme.colors.neutral.default};
        max-height: 256px;
        border-radius: 0 0 4px 4px;
      }
      ${FeaturedImage} {
        margin-bottom: 0;
        border-radius: 4px 4px 0 0;
      }
      ${Title} {
        margin-top: 16px;
      }
    `};

  @media (min-width: ${(props) => props.theme.breakpoints.min_sm}px) {
    ${(props) =>
      props.big &&
      css`
        margin-bottom: 0;
        ${FeaturedImage} {
          height: 320px;
        }
      `};

    ${(props) =>
      props.small &&
      css`
        display: inline-flex;
        margin-bottom: 12px;

        ${FeaturedImage} {
          height: 144px;
          width: 256px;
          margin-bottom: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }

        ${Content} {
          width: calc(100% - 256px);
          padding-left: 24px;
          ${Title} {
            margin-top: 8px;
            margin-bottom: 0;
            font-size: 16px;
          }
        }
      `};

    ${(props) =>
      props.press &&
      css`
        width: 100%;
        margin: 24px 0 0;
      `};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 12px;

    ${(props) =>
      props.press &&
      css`
        margin-bottom: 11px;
      `};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-basis: 100%;
    max-width: 100%;
    margin-top: 24px;
    margin-bottom: 16px;
    padding: 0 ${({ press, resources }) => (press || resources ? 0 : 24)}px;

    ${(props) =>
      props.press &&
      css`
        margin-bottom: 0;
      `};
    ${(props) =>
      props.career &&
      css`
        margin-bottom: 24px;
      `};

    ${(props) =>
      props.postWithBackground &&
      css`
        ${Content} {
          padding: 24px;
          height: 230px;
        }
        ${FeaturedImage} {
          height: 186px;
        }
      `};
  }
`;

export const FeaturedImage = styled(DefaultFeaturedImage)`
  background-color: ${(props) => props.theme.colors.secondary.light};
  border-radius: 4px;
  margin-bottom: 24px;
  height: 256px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: 184px;
  }
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 28px;
  opacity: 0.8;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 84px;
  max-height: 84px;
`;
