import styled from 'styled-components';
import cn from 'classnames';
import ErrorLabel from './ErrorLabel';
import Button from './Button';
import SuccessIcon from '@/public/assets/icons/path.svg';

const TextBoxSubscribe = ({
  className,
  type,
  placeholder,
  value,
  name,
  success,
  loading,
  ariaLabel,
  onChange,
  disabled,
  error,
  errorLabel,
  field,
}) => {
  return (
    <div className={className}>
      <div className={cn('wrapper', { error })}>
        <input
          className={cn('textbox', { error })}
          type={type}
          placeholder={placeholder}
          value={value}
          name={name}
          aria-label={ariaLabel}
          onChange={onChange}
          disabled={disabled}
          {...field}
        />
        <Button type="submit">
          {loading ? (
            <Dots>
              <div className="snippet" data-title=".dot-pulse">
                <div className="stage">
                  <div className="dot-pulse" />
                </div>
              </div>
            </Dots>
          ) : (
            <>
              {success && !loading && <SuccessIcon />}
              {!success && !loading ? 'Subscribe' : 'Subscribed'}
            </>
          )}
        </Button>
      </div>

      {errorLabel && <ErrorLabel error={error}>{errorLabel}</ErrorLabel>}
    </div>
  );
};

const TextBoxSubscribeStyled = styled(TextBoxSubscribe)`
  max-width: 511px;
  width: 100%;
  margin: auto;
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 511px;
    height: 64px;
    color: #1f222c;
    background-color: #ffffff;
    border-radius: 1000px;
    border: 2px solid #ffffff;

    &.error {
      border: 1px solid #f46262;
    }

    .textbox {
      flex-grow: 1;
      font-family: ${(props) => props.theme.font.family.body};
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      border: none;
      padding: 18px 34px 18px 34px;
      background-color: transparent;

      &::placeholder {
        font-family: ${(props) => props.theme.font.family.body};
        color: #1f222c;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        opacity: 0.6;
      }

      &::-ms-reveal {
        display: none;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    .wrapper {
      color: unset;
      width: 100%;
      background-color: unset;
      border-radius: unset;
      border: unset;
      flex-direction: column;
      padding: 24px;

      &.error {
        border: unset;
      }

      .textbox {
        width: 100%;
        height: 48px;
        color: #1f222c;
        background-color: #ffffff;
        border-radius: 1000px;
        border: 2px solid #ffffff;

        &.error {
          border: 1px solid #f46262;
        }
      }
    }
  }
`;

const Dots = styled.div`
  .dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ffffff;
    color: #ffffff;
    box-shadow: 9999px 0 0 -5px #ffffff;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: 0.25s;
  }

  .dot-pulse::before,
  .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ffffff;
    color: #ffffff;
  }

  .dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #ffffff;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
  }

  .dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #ffffff;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: 0.5s;
  }

  @keyframes dotPulseBefore {
    0% {
      box-shadow: 9984px 0 0 -5px #ffffff;
    }
    30% {
      box-shadow: 9984px 0 0 2px #ffffff;
    }
    60%,
    100% {
      box-shadow: 9984px 0 0 -5px #ffffff;
    }
  }

  @keyframes dotPulse {
    0% {
      box-shadow: 9999px 0 0 -5px #ffffff;
    }
    30% {
      box-shadow: 9999px 0 0 2px #ffffff;
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -5px #ffffff;
    }
  }

  @keyframes dotPulseAfter {
    0% {
      box-shadow: 10014px 0 0 -5px #ffffff;
    }
    30% {
      box-shadow: 10014px 0 0 2px #ffffff;
    }
    60%,
    100% {
      box-shadow: 10014px 0 0 -5px #ffffff;
    }
  }
`;

export default TextBoxSubscribeStyled;
