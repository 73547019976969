import axios from 'axios';

export function requestDemo(payload) {
  // Payload: { firstName, lastName, email, businesstype, title, company, numberemployees, phone, howdidyouhearaboutus }
  return axios.post(`/api/request-demo`, payload);
}

export function subscribeNewsletter(payload) {
  // Payload: { email }
  return axios.post(`/api/subscribe-newsletter`, payload);
}
