import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Container } from '@/components/core/flexbox';
import { SinglePost } from './SinglePost';
import LoadingPost from './LoadingPost';
import LoadMore from './LoadMore';
import PostsSlider from './PostsSlider';
import { NoData, Error } from './FeedbackMessage';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';
import POST_TYPES from '../post-types';
import { LinkButton } from '@/components/core/Button';

const PostListHeader = ({ category, back, seeAll, loading }) => (
  <Container>
    <ListDetails>
      <Category>{category}</Category>
      {back && (
        <LinkButton type="button" onClick={back} disabled={loading} $isLeft>
          Back
        </LinkButton>
      )}
      {seeAll && (
        <LinkButton type="button" onClick={seeAll}>
          See all
        </LinkButton>
      )}
    </ListDetails>
  </Container>
);

const PostsList = ({
  className,
  press,
  career = false,
  category,
  columns = 3,
  posts,
  seeAll,
  back,
  mostRecent,
  loading,
  loadingMore,
  loadMore,
  meta,
  sliderInMobile = true,
  list = false,
  hideWhenEmpty,
  hasError,
  postWithBackground,
  postType,
  minimal,
  resources,
  showDate = true,
  ...cardProps
}) => {
  const isTablet = useBetterMediaQuery(
    `(min-width: ${theme.breakpoints.min_md}px) and (max-width: ${theme.breakpoints.max_md}px)`,
  );
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);
  const tabletPosts = useMemo(
    () => (isTablet && !list ? posts.slice(0, 2) : posts),
    [isTablet, list, posts],
  );

  if (hideWhenEmpty && (!posts || !posts?.length) && !loading) return null;

  if (isMobile && sliderInMobile) {
    return (
      <ListWrapper className={className} data-testid="posts-mobile">
        {category && (
          <PostListHeader loading={loading} seeAll={seeAll} back={back} category={category} />
        )}
        <PostsSlider
          posts={posts}
          press={press}
          postWithBackground={postWithBackground}
          postType={postType}
          minimal={minimal}
          showDate={showDate}
          career={career}
          {...cardProps}
        />
      </ListWrapper>
    );
  }

  const isPress = postType === POST_TYPES.PRESS_PAGE;

  return (
    <ListWrapper className={className}>
      {!isPress && category && (
        <PostListHeader loading={loading} seeAll={seeAll} back={back} category={category} />
      )}

      <Container>
        {!hasError && mostRecent && !isTablet && !loading && posts.length && (
          <MostRecentGrid>
            <SinglePost
              post={posts[0]}
              key={posts[0]?.id || posts[0]?.title}
              press={isPress}
              career={career}
              big
              postType={postType}
              minimal={minimal}
              showDate={showDate}
              {...cardProps}
            />
            <div>
              {posts?.slice(1).map((post) => (
                <SinglePost
                  post={post}
                  key={post?.id || post?.title}
                  press={isPress}
                  career={career}
                  small
                  postType={postType}
                  minimal={minimal}
                  showDate={showDate}
                  {...cardProps}
                />
              ))}
            </div>
          </MostRecentGrid>
        )}

        {!hasError && (!mostRecent || isTablet) && (
          <List
            $press={isPress}
            $postWithBackground={postWithBackground}
            $columns={columns}
            $minimal={minimal}
            data-testid="posts-tabletPosts"
          >
            {!loading &&
              tabletPosts.map((post) => (
                <SinglePost
                  post={post}
                  key={post?.id || post?.title}
                  press={isPress}
                  career={career}
                  postWithBackground={postWithBackground}
                  postType={postType}
                  minimal={minimal}
                  showDate={showDate}
                  resources={resources}
                  {...cardProps}
                />
              ))}
            {(loading || loadingMore) &&
              Array(loading && !isMobile && !isTablet ? 6 : 3)
                .fill(0)
                .map((v, index) => <LoadingPost key={`loading-${index}`} />)}
          </List>
        )}

        {hasError && <Error />}
        {!hasError && !loading && (!posts || !posts.length) && (
          <NoData category={category} back={back} />
        )}
        {loadMore && meta && !loadingMore && <LoadMore meta={meta} loadMore={loadMore} />}
      </Container>
    </ListWrapper>
  );
};

const ListWrapper = styled.div`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation-name: fadeIn;
  animation-duration: 0.3s;
`;

const ListDetails = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(31, 34, 44, 0.1);
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 16px;
  }
`;

const Category = styled.div`
  font-family: ${(props) => props.theme.font.family.heading};
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 18px;
  }
`;

export const List = styled.div`
  display: grid;
  column-gap: ${(props) =>
    props.$postWithBackground || props.$press || props.$minimal ? '24px' : '32px'};
  grid-template-columns: repeat(${(props) => props.$columns}, 1fr);
  box-sizing: border-box;
  flex: 0 1 auto;
  width: 100%;
  margin: 20px 0 ${({ press }) => (press ? 11 : 28)}px;
  row-gap: ${(props) => (props.$minimal ? '24px' : '0')};
  grid-auto-rows: 1fr;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    column-gap: 24px;
    grid-template-columns: 1fr 1fr;
    margin: 20px 0 12px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    grid-template-columns: 1fr;
  }

  > a {
    box-sizing: border-box;

    ${(props) =>
      props.$postWithBackground &&
      !props.$press &&
      css`
        display: grid;
        grid-template-rows: auto 1fr;
      `};

    ${(props) =>
      props.$press &&
      css`
        @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
          &:nth-child(1),
          &:nth-child(2) {
            margin-top: 30px;
          }
        }
      `};
  }
`;

const MostRecentGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  box-sizing: border-box;
  flex: 0 1 auto;
  width: 100%;
  margin: 20px 0 64px 0;
  overflow: hidden;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    > div,
    > a {
      flex-basis: 50%;
      max-width: 50%;
      &:first-child {
        padding-right: 16px;
      }
      &:nth-child(2) {
        padding-left: 16px;
        > div,
        > a {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    > div,
    > a {
      flex-basis: 100%;
      max-width: 100%;
      &:nth-child(2) {
        > div {
          width: 100%;
        }
      }
    }
  }
`;

export default PostsList;
