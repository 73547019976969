import styled from 'styled-components';
import { Button } from '@/components/core/Button';

const LoadMore = ({ meta, loading, loadMore }) => {
  if (!meta || !meta.pagination || meta.pagination.page >= meta.pagination.pageCount) {
    return null;
  }

  return (
    <ButtonWrapper className="load-more-wrapper">
      <Button variant="secondary" loading={loading} onClick={loadMore}>
        Load More
      </Button>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 45px;
`;
export default LoadMore;
