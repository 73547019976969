import { notFound } from 'next/navigation';

/**
 * Asynchronously fetches data from a specified URL and returns the data as a JSON object.
 *
 * @template TData The expected type of the fetched data.
 * @param {string} url The URL from which to fetch data.
 * @param {RequestInit} [options] Optional fetch options to customize the request.
 * @returns {Promise<TData>} A promise that resolves with the fetched data as a JSON object.
 * @throws {Response} Throws the response object if the fetch operation fails or returns a non-2xx status code.
 */
const fetcher = async (url, options, redirectNotFound = false) => {
  const response = await fetch(url, options);

  if (redirectNotFound && response?.status === 404) {
    notFound();
  }

  if (response.status < 200 || response.status >= 300) {
    throw response;
  }

  return await response.json();
};

export default fetcher;
