import styled from 'styled-components';

const CircleOutlined = styled.div`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border: 3px solid ${(props) => props.color};
  border-radius: 50%;
  z-index: 10;
`;

export default CircleOutlined;
