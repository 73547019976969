'use client';

import { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import styled from 'styled-components';
import Textbox from './TextBoxSubscribe';
import Button from './Button';
import ErrorLabel from './ErrorLabel';
import { subscribeNewsletter } from '@/services/contacts';

const InputSubscriber = () => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [loading]);

  return (
    <Formik
      className="formik"
      initialValues={{
        email: '',
      }}
      onSubmit={async (values, { resetForm }) => {
        setLoading(true);
        await subscribeNewsletter({
          email: values.email,
        });

        setSuccess(true);
        resetForm({
          email: '',
        });
      }}
    >
      {({ handleSubmit, touched, errors, values }) => (
        <StyledForm autoComplete="off" onSubmit={handleSubmit}>
          <EmailSubmit>
            <Field
              component={Textbox}
              name="email"
              type="email"
              label="Email"
              ariaLabel="Email"
              placeholder="example@gmail.com"
              validate={(value) => {
                const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
                return !emailRegex.test(value);
              }}
              success={success}
              loading={loading}
              error={errors.email && touched.email}
              errorLabel={values.email ? 'Please enter a valid email address' : 'Email is required'}
            />
          </EmailSubmit>
        </StyledForm>
      )}
    </Formik>
  );
};

const StyledForm = styled(Form)`
  position: relative;
  width: 100%;
  flex: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 32px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 24px;
    padding-bottom: 64px;
  }
`;

const EmailSubmit = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  ${ErrorLabel} {
    position: absolute;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    justify-content: center;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column;

    ${Button} {
      width: 100%;
      text-align: center;
    }

    ${ErrorLabel} {
      margin-left: 25px;
    }
  }

  @keyframes animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default InputSubscriber;
