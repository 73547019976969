import { CircleFilled, CircleOutlined } from '@/components/core/Circle';
import theme from '@/utils/styles-variables';

const DetailsGraphicElements = ({ outlinedStyle, filledStyle }) => {
  return (
    <>
      <CircleOutlined
        style={{ position: 'absolute', top: '15px', right: '130px', ...outlinedStyle }}
        size="24px"
        color={theme.colors.primary.default}
      />

      <CircleFilled
        style={{ position: 'absolute', top: '75px', right: '40px', ...filledStyle }}
        size="46px"
        color={theme.colors.primary.light}
      />
    </>
  );
};

export default DetailsGraphicElements;
