import styled from 'styled-components';
import FeedBackMessage from './FeedBackMessage';

const NoData = ({ category, back }) => (
  <FeedBackMessage
    title={
      <>
        No results found in <Category>{category}</Category>
      </>
    }
    subtitle={
      <>
        Refine your search and try again or go back to <Button onClick={back}>newsroom</Button>.
      </>
    }
  />
);

const Category = styled.span`
  text-transform: capitalize;
`;

const Button = styled.button`
  color: ${(props) => props.theme.colors.primary.default};
  cursor: pointer;
`;

export default NoData;
