import styled from 'styled-components';
import { NeutralButton } from '@/components/core/Button';

const Categories = ({ categories, changeCategory, selectedCategory, loading }) => {
  if (!categories) {
    return null;
  }

  return (
    <ListWrapper>
      <List>
        <NeutralButton
          onClick={() => changeCategory('all')}
          active={selectedCategory === 'all'}
          loading={loading}
        >
          All
        </NeutralButton>
        {categories.map((category) => (
          <>
            <NeutralButton
              data-testid="category"
              key={category.name}
              onClick={() => changeCategory(category.name)}
              $active={selectedCategory === category.name}
              loading={loading}
            >
              {category.displayName}
            </NeutralButton>
          </>
        ))}
      </List>
    </ListWrapper>
  );
};

const ListWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 100vw;
  }
`;

const List = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 8px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 0 24px 0 24px;
  }
`;

export default Categories;
