import styled from 'styled-components';
import {
  FeaturedImage as DefaultFeaturedImage,
  Post as DefaultPost,
  Title as DefaultTitle,
  SecondaryImage as DefaultSecondaryImage,
} from './SinglePostStyles';

export const Title = styled(DefaultTitle)`
  padding-top: 12px;
`;

export const FeaturedImage = styled(DefaultFeaturedImage)`
  border-radius: inherit;
  grid-column: 1;
  grid-row: 1;
  height: 100%;

  img {
    transition: transform ease-out 120ms;
  }
`;

// 8-digit hexadecimal opacity values
// https://davidwalsh.name/hex-opacity
export const Content = styled.div`
  align-content: end;
  background: ${(props) =>
    `linear-gradient(to bottom, ${props.$color || props.theme.colors.grey.dark}00, ${
      props.$color || props.theme.colors.grey.dark
    }33 33.33%, ${props.$color || props.theme.colors.grey.dark}CC 66.67%, ${
      props.$color || props.theme.colors.grey.dark
    })`};
  border-radius: inherit;
  display: grid;
  grid-column: 1;
  grid-row: 1;
  justify-items: start;
  padding: 32px;
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 24px;
  }

  &:before {
    background-color: ${(props) => props.$color || props.theme.colors.grey.dark};
    content: '';
    display: block;
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: opacity ease-out 120ms;
    width: 100%;
  }

  &:hover {
    transform: none;

    &::before {
      opacity: 0.5;
    }
  }
`;

export const Post = styled(DefaultPost)`
  background-color: ${(props) => props.theme.colors.neutral.default};
  border-radius: 8px;
  color: ${(props) => props.theme.colors.white};
  display: grid;
  min-height: 280px;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    min-height: 240px;
  }

  .postButton {
    margin-top: 12px;
    transition-duration: 120ms;
    transition-timing-function: ease-out;

    &.postButtonHidden {
      height: 0;
      margin-top: 0;
      opacity: 0;
      visibility: hidden;
    }
  }

  &:hover {
    ${FeaturedImage} img {
      transform: scale(1.03);

      @media (prefers-reduced-motion) {
        transform: none;
      }
    }

    .postButton {
      height: 24px;
      margin-top: 12px;
      opacity: 1;
      pointer-events: none;
      visibility: visible;
    }
  }
`;
export const SecondaryImage = styled(DefaultSecondaryImage)`
  position: relative;
  height: 32px;
  width: 100%;

  img {
    object-fit: contain;
    object-position: left;
    height: 50px;
    width: 100%;
  }
`;
