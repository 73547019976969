'use client';

import { useEffect, useState } from 'react';

/**
 * Used to determine if the document matches the media query specified
 * Modified from link below
 * @see https://observablehq.com/@werehamster/avoiding-hydration-mismatch-when-using-react-hooks
 *
 * @param {MediaQueryListEvent['media']} mediaQueryString
 * @param {MediaQueryList['matches']} defaultValue
 *
 * @return {boolean}
 */
export default function useBetterMediaQuery(mediaQueryString, defaultValue = false) {
  const [matches, setMatches] = useState(defaultValue);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQueryString);
    const documentChangeHandler = () => setMatches(!!mediaQueryList.matches);

    try {
      mediaQueryList.addEventListener('change', documentChangeHandler);
    } catch (e) {
      // Safari isn't supporting mediaQueryList.addEventListener
      mediaQueryList.addListener(documentChangeHandler);
    }

    documentChangeHandler();

    return () => {
      try {
        mediaQueryList.removeEventListener('change', documentChangeHandler);
      } catch (e) {
        // Safari isn't supporting mediaQueryList.removeEventListener
        mediaQueryList.removeListener(documentChangeHandler);
      }
    };
  }, [mediaQueryString]);

  return matches;
}
