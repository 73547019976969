import styled from 'styled-components';
import useReadingTime from '@/utils/useReadingTime';

const ReadingTime = ({ content, className, hasReadingTime, wordCount }) => {
  const readingTime = useReadingTime(content, wordCount);

  if (!hasReadingTime || !readingTime) return null;

  return <Label className={className}>{readingTime} min read</Label>;
};

const Label = styled.div`
  font-family: ${(props) => props.theme.font.family.body};
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #1f222c;
  opacity: 0.7;
  user-select: text;
`;

export default ReadingTime;
