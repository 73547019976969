import styled from 'styled-components';

const FeedBackMessage = ({ title, subtitle }) => (
  <FeedBackWrapper>
    <div>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </div>
  </FeedBackWrapper>
);

const FeedBackWrapper = styled.div`
  padding: 115px 0 62px 0;
  width: 100%;
  text-align: center;
  animation-name: slideIn;
  animation-duration: 2s;
`;

const Title = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

const Subtitle = styled.div`
  font-size: 16px;
  line-height: 28px;
  margin-top: 16px;
`;

export default FeedBackMessage;
