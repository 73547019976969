import styled from 'styled-components';

const Title = styled.h2`
  font-family: ${(props) => props.theme.font.family.body};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  max-width: 520px;
  margin-top: 32px;
`;

export default Title;
