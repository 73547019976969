import { memo } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import clsx from 'clsx';
import DateComponent from '../../DateComponent';
import getImgSrcWorkaround from '@/utils/images';
import { Content, FeaturedImage, Post, SecondaryImage, Title } from './SinglePostMinimalStyles';
import { LinkButton } from '@/components/core/Button';

const SinglePostMinimal = ({
  animation = 'slideIn',
  buttonLabel = 'Read More',
  className,
  color,
  post,
  onClick,
  hiddenButton,
  linkProps = {},
  url,
  showDate = false,
}) => {
  if (!post) return null;
  const { title, publishedOn, publishedAt, featuredImage } = post;
  const publishedDate = publishedOn || publishedAt;

  return (
    <Link href={url} passHref legacyBehavior>
      <Post
        animation={animation}
        className={clsx('linkButtonGroup', className)}
        onClick={onClick}
        {...linkProps}
      >
        {featuredImage && (
          <FeaturedImage>
            <Image
              data-testid="post-featureImage"
              src={getImgSrcWorkaround(featuredImage.url)}
              alt={featuredImage.alternativeText || ''}
              fill
            />
          </FeaturedImage>
        )}
        <Content $color={color}>
          {post?.imageList && (
            <SecondaryImage>
              <Image
                src={getImgSrcWorkaround(post.imageList.url)}
                alt={post.imageList.alternativeText || ''}
                fill
              />
            </SecondaryImage>
          )}
          {showDate && <DateComponent publishedOn={publishedDate} minimal />}
          <Title>{title}</Title>
          <LinkButton
            data-testid="post-button"
            forwardedAs="span"
            className={clsx('postButton', { postButtonHidden: hiddenButton })}
          >
            {buttonLabel}
          </LinkButton>
        </Content>
      </Post>
    </Link>
  );
};

export default memo(SinglePostMinimal);
