import styled from 'styled-components';

const LoadingPost = () => (
  <Post>
    <ImageWrapper />
    <Content>
      <DateWrapper />
      <Title />
      <Description />
    </Content>
  </Post>
);

const Post = styled.div`
  flex-basis: 33.33%;
  max-width: 33.33%;
  padding-left: 16px;
  padding-right: 16px;
  margin: 20px 0;
  box-sizing: border-box;
  pointer-events: none;

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  animation-name: slideIn;
  animation-duration: 2s;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-basis: 100%;
    max-width: 100%;
    padding: 0;
  }
`;

const Content = styled.div`
  box-sizing: border-box;
`;

const AnimatedDiv = styled.div`
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }

  animation-duration: 1.25s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
`;

const ImageWrapper = styled(AnimatedDiv)`
  background-color: ${(props) => props.theme.colors.primary.light};
  border-radius: 4px;
  overflow: hidden;
  height: 256px;
  animation-duration: 1.25s;
`;

const Title = styled(AnimatedDiv)`
  height: 24px;
  width: 80%;
  margin: 24px 0 16px 0;
`;

const DateWrapper = styled(AnimatedDiv)`
  display: inline-flex;
  height: 24px;
  width: 60%;
  margin-top: 24px;
`;

const Description = styled(AnimatedDiv)`
  height: 28px;
  margin-top: 24px;
`;

export default LoadingPost;
