import styled from 'styled-components';
import cn from 'classnames';
import { Button } from '@/components/core/Button';

const SubscribeButton = ({ className, onClick, disabled, children }) => {
  return (
    <Button
      className={cn(className)}
      variant="tertiary"
      onClick={onClick}
      disabled={disabled}
      aria-label="Subscribe button"
      type="submit"
    >
      {children}
    </Button>
  );
};

const SubscribeButtonStyled = styled(SubscribeButton)`
  margin: 8px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 25px;
    margin-bottom: 0;
    width: 100%;
  }
`;

export default SubscribeButtonStyled;
