import styled, { css } from 'styled-components';
import ReadingTime from '@/components/ReadingTime';

const DateComponent = ({
  category,
  minimal,
  publishedOn,
  content,
  details,
  readingTime,
  hasReadingTime,
  altFont,
  career,
  wordCount,
  hideDate = false,
}) => {
  return (
    <Wrapper>
      {category && !minimal && (
        <Category data-testid="date-category" career={career}>
          {category}
        </Category>
      )}

      <DateWrapper
        data-testid="date-wrapper"
        $details={details}
        $minimal={minimal}
        $altFont={altFont}
        career={career}
      >
        {!hideDate && (
          <DateInfo $minimal={minimal} $noCategory={!category}>
            <DateLabel $minimal={minimal} career={career}>
              {new Date(publishedOn).toLocaleDateString('en-US', { dateStyle: 'long' })}
            </DateLabel>
          </DateInfo>
        )}
        <DateInfo $minimal={minimal} data-testid="date-readingTime">
          {hasReadingTime && !readingTime && (
            <ReadingTime content={content} wordCount={wordCount} hasReadingTime={hasReadingTime} />
          )}
          {readingTime && !content && <>{readingTime} min read</>}
        </DateInfo>
      </DateWrapper>
    </Wrapper>
  );
};

export const Category = styled.div`
  font-family: ${(props) => props.theme.font.family.heading};
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  opacity: 1;

  ${(props) =>
    !props.career &&
    css`
      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        flex: 0 0 100%;
      }
    `};
`;

export const DateWrapper = styled.div`
  display: flex;
  font-family: ${(props) =>
    props.$minimal || props.$altFont
      ? props.theme.font.family.body
      : props.theme.font.family.heading};
  position: relative;

  ${(props) =>
    props.$details &&
    css`
      margin-bottom: 60px;

      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        margin-bottom: 52px;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        margin-bottom: 32px;
      }
    `}
  ${(props) =>
    props.career &&
    css`
      color: ${(props) => props.theme.colors.grey.default};
    `};
`;

const DateInfo = styled.div`
  font-size: 14px;
  padding-left: ${(props) => (props.$noCategory || props.$minimal ? '0' : '22px')};
  position: relative;

  &:before {
    position: absolute;
    opacity: 0.3;
    content: ${(props) => (props.$noCategory || props.$minimal ? 'none' : '""')};
    top: 10px;
    left: 8px;
    height: 6px;
    width: 6px;
    background-color: ${(props) => props.theme.colors.grey.dark};
    border-radius: 50%;
  }

  ${(props) =>
    props.career &&
    css`
      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        &:first-child {
          padding-left: unset;
          position: unset;

          &:before {
            content: none;
          }
        }
      }
    `};

  &:empty {
    display: none;
  }
`;

const DateLabel = styled.div`
  opacity: ${(props) => (props.$minimal || props.career ? '1' : '0.7')};
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default DateComponent;
