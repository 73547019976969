import dynamic from 'next/dynamic';
import { useMemo } from 'react';
import { getPostType } from '@/components/shared/Blog/post-types';

const SinglePostDefault = dynamic(() => import('./SinglePostDefault'), {
  loading: () => <div style={{ height: 500 }} />,
});

const SinglePostMinimal = dynamic(() => import('./SinglePostMinimal'), {
  loading: () => <div style={{ height: 260 }} />,
});

const SinglePost = ({ loading, minimal, post, postType, ...props }) => {
  const url = useMemo(() => {
    const postTypeData = post?.postType ? getPostType(post?.__component) : postType;

    if (postTypeData?.url && post?.slug) {
      return `/${postTypeData.url}/${post.slug}`;
    }

    return post?.url || '';
  }, [post, postType]);
  const linkProps = useMemo(() => {
    try {
      new URL(url);

      return { target: '_blank', rel: 'noopener' };
    } catch {
      return {};
    }
  }, [url]);

  if (!post && !loading) return null;

  return minimal ? (
    <SinglePostMinimal post={post} postType={postType} url={url} {...props} {...linkProps} />
  ) : (
    <SinglePostDefault post={post} postType={postType} url={url} {...props} {...linkProps} />
  );
};

export default SinglePost;
