import styled from 'styled-components';

const CircleFilled = styled.div`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  background: ${(props) => props.color};
  border-radius: 50%;
  z-index: -1;
`;

export default CircleFilled;
