import styled from 'styled-components';
import cn from 'classnames';
import Icon from '@/public/assets/icons/error.svg';

const ErrorLabel = ({ error, children, className }) => (
  <div className={cn(className, { error })}>
    <Icon />
    <div className="error-label">{children}</div>
  </div>
);

const ErrorLabelStyled = styled(ErrorLabel)`
  visibility: hidden;
  height: 0;
  transition: height 0.3s ease;
  margin-top: 11px;

  &.error {
    visibility: visible;
    display: flex;
    margin-left: 10px;
    height: 20px;
  }

  .error-label {
    font-family: ${(props) => props.theme.font.family.body};
    font-weight: 500;
    margin-left: 10px;
    color: #1f222c;
    font-size: 13px;
    line-height: 16px;
  }
`;

export default ErrorLabelStyled;
