import qs from 'qs';
import cmsClient from '@/lib/cms-client';

const POSTS_PER_PAGE = 9;

class BlogService {
  static async getLatestPost({ postType }) {
    return await cmsClient(`/${postType}/latest-post`);
  }

  static async getLatestPosts({ limit = 3, postType }) {
    let query = {};

    if (limit) {
      query.limit = limit;
    }

    return await cmsClient(`/${postType}/latest-posts/?${qs.stringify(query)}`);
  }

  static async getCategories({ postType }) {
    return await cmsClient(`/blog-category/getPostsCategories/${postType}`).then(
      ({ data }) => data,
    );
  }

  static async getPosts({ page, category, limit, postType }) {
    let query = {
      pagination: {
        page,
        pageSize: limit ?? POSTS_PER_PAGE,
      },
    };

    if (category && category !== 'all') {
      query.category = category;
    }

    return await cmsClient(`/${postType}/getPosts/?${qs.stringify(query)}`);
  }

  static async getBlogPosts({
    page,
    pageSize = POSTS_PER_PAGE,
    category,
    excludedIds = [],
    postType,
  }) {
    const exclude = excludedIds.map((id) => ({ id: { $ne: id } }));
    let query = {
      sort: ['publishedOn:desc', 'id:desc'],
      populate: ['featuredImage', 'category'],
      filters: {
        $and: exclude,
        $or: [
          {
            showOnList: { $null: true },
          },
          {
            showOnList: { $eq: true },
          },
        ],
      },
      pagination: {
        page,
        pageSize: pageSize,
      },
    };

    if (category && category !== 'all') {
      query.filters = {
        ...query.filters,
        category: {
          name: {
            $eq: category,
          },
        },
      };
    }
    return await cmsClient(`/${postType}?${qs.stringify(query)}`);
  }

  static async getBlogPost({ slug, postType, preview, getGated, token }) {
    const isPreview = preview === true || preview === 'true' || preview === '1';

    return await cmsClient(
      `/${postType}/findBySlug/${slug}?preview=${isPreview}&getGated=${getGated}&accessToken=${token}`,
      {
        ...(isPreview ? { cache: 'no-store' } : { next: { revalidate: 120 } }),
        headers: {
          Authorization: `Bearer ${process.env.GATED_RESOURCES_TOKEN}`,
        },
      },
      true,
    );
  }

  static async getMostRecent({ limit, postType }) {
    let query = {};

    if (limit) {
      query.limit = limit;
    }

    return await cmsClient(`/${postType}/most-recent/?${qs.stringify(query)}`);
  }

  static async getBlogPostsByCategory({ postType }) {
    return await cmsClient(`/${postType}/groupByCategories`);
  }

  static async getRelatedPosts({ slug, postType }) {
    return await cmsClient(`/${postType}/relatedPosts?slug=${slug}`);
  }
}
export default BlogService;
